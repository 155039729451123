/**
 * Check if the values is undefined
 *
 * code: ```value === undefined```
 * */
export function isUndefined(value: any) {
	return value === undefined;
}

/**
 * Check if the values is null
 *
 * code: ```value === null```
 * */
export function isNull(value: any) {
	return value === null;
}

/**
 * Check if the values is null or undefined
 *
 * code: ```value === undefined || value === null```
 * */
export function isNullOrUndefined(value: any) {
	return isUndefined(value) || isNull(value);
}

/**
 * This function return `true` if the value is not null, not undefined and typeof is 'string', otherwise `false`
 *
 * code: ```!isNullOrUndefined(value) && typeof value === 'string'```
 *
 * @returns boolean
 * */
export function isValidString(value: any) {
	return !isNullOrUndefined(value) && typeof value === 'string';
}

/**
 * This function return `true` if the value is not null, not undefined and typeof is 'function', otherwise `false`
 *
 * code: ```!isNullOrUndefined(value) && typeof value === 'function'```
 *
 * @returns boolean
 * */
export function isValidFunction(value: any) {
	return !isNullOrUndefined(value) && typeof value === 'function';
}

/**
 * This function return `true` if the value is not null, not undefined, typeof is 'string' and different of '', otherwise `false`
 *
 * code: ```isValidString(value) && value !== ''```
 *
 * @returns boolean
 * */
export function isNotEmptyString(value: string) {
	return isValidString(value) && value !== '';
}

/**
 * This function return `true` if the value is not null, not undefined, typeof is 'string' and different of '', otherwise `false`
 *
 * code: ```isValidString(value) && value !== ''```
 *
 * @returns boolean
 * */
export function isDefinedString(value: string) { return isNotEmptyString(value)};

/**
 * This function return `true` if the value is not null, not undefined, typeof is 'string' and different of '', otherwise `false`
 *
 * code: ```isValidString(value) && value !== ''```
 *
 * @returns boolean
 * */
export function isProvidedString(value: string) { return isNotEmptyString(value)};

/**
 * This function return `true` if the value is not null, not undefined, typeof is 'string' and different of '', otherwise `false`
 *
 * code: ```isValidString(value) && value !== ''```
 *
 * @returns boolean
 * */
export function isFilledString(value: string) { return isNotEmptyString(value)};

/**
 * This function return `true` if the value is not null, not undefined, and equal to '', otherwise `false`
 *
 * code: ```isValidString(value) && value === ''```
 *
 * @returns boolean
 */
export function isEmptyString(value: string) {
	return isValidString(value) && value === '';
}

/**
 * This function return `true` if value's type is 'number', otherwise `false
 *
 * code: ```typeof value === 'number'```
 *
 * @returns boolean
 * */
export function isNumber(value: any) {
	return typeof value === 'number';
}

/**
 * This function return `true` if value is a number and is not a NaN
 *
 * code: ```return isNumber(value) && !isNaN(value)```
 *
 * @returns boolean
 * */
export function isNotNaNNumber(value: number) {
	return isNumber(value) && !isNaN(value);
}

/**
 * @returns boolean
 *
 * return `true` if typeof is 'number' and isNaN, otherwise `false`
 *
 * ```return isNumber(value) && isNaN(value)```
 * */
export function isNaNNumber(value: number) {
	return isNumber(value) && isNaN(value);
}

/**
 * @returns boolean
 *
 * return `true` if the value is not null, not undefined, typeof if 'number' and it's not a NaN number, otherwise `false`
 *
 * ```!isNullOrUndefined(value) && isNotNaNNumber(value)```
 * */
export function isValidNumber(value: any) {
	return !isNullOrUndefined(value) && isNotNaNNumber(value);
}

/**
 * ```!isNaN(parseFloat(value as string))```
 * */
export function isFloat(value: string | number) {
	return !isNaN(parseFloat(value as string));
}

/**
 * ```isFloat(value) && isFloatGreaterThan(value)```
 * */
export function isPositiveFloat(value: any) {
	return isFloat(value) && isFloatGreaterThan(value);
}

/**
 * ```isFloat(value) && isFloat(valueToCompare) && parseFloat(value) > valueToCompare```
 * */
export function isFloatGreaterThan(value: string, valueToCompare = 0) {
	return isFloat(value) && isFloat(valueToCompare) && parseFloat(value) > valueToCompare;
}

/**
 * ```isFloat(value) && isFloat(valueToCompare) && parseFloat(value) < valueToCompare```
 * */
export function isFloatLessThan(value: string, valueToCompare = 0) {
	return isFloat(value) && isFloat(valueToCompare) && parseFloat(value) < valueToCompare;
}

/**
 * ```typeof value === 'string'```
 * */
export function isString(value: string) {
	return typeof value === 'string';
}

/**
 * ```!isNullOrUndefined(value) && Array.isArray(value)```
 * */
export function isArray(value: any[]) {
	return !isNullOrUndefined(value) && Array.isArray(value);
}

/**
 * This function return `true` if the value is not null, not undefined, is an array and length is greater than zero, otherwise `false`
 *
 * code: ```isArray(value) && value.length > 0```
 *
 * @returns boolean
 * */
export function isNoEmptyArray(value: any[]) {
	return isArray(value) && value.length > 0;
}

/**
 * This function return `true` if the value is not null, not undefined, is an array and length is greater than zero, otherwise `false`
 *
 * code: ```isArray(value) && value.length > 0```
 *
 * @returns boolean
 * */
export function isFilledArray(value: any[]) { return isNoEmptyArray(value)};

/**
 * ```!isNullOrUndefined(value) && typeof value === 'object'```
 * */
export function isObject(value: any) {
	return !isNullOrUndefined(value) && typeof value === 'object';
}

/**
 * Default: milliseconds = 1000
 *
 * ```new Promise<void>((resolve) => setTimeout(() => resolve(), milliseconds))```
 * */
export function delay(milliseconds = 1000) {
	return new Promise<void>((resolve) => setTimeout(() => resolve(), milliseconds));
}

/**
 * ```!isNullOrUndefined(value) && typeof value === 'object'```
 * */
export function closetValue(array: number[], goal: number) {
	return !isNoEmptyArray(array) ? null : array.reduce((prev, curr) => Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
}

/**
 * ```(isString(value) && isString(toRemove)) ? value.split(toRemove).join('') : value```
 * */
export function removeAllSubStr(value: string, toRemove: string) {
	return (isString(value) && isString(toRemove)) ? value.split(toRemove).join('') : value;
}

/**
 * ```!isNullOrUndefined(new URL(value))```
 * */
export function isValidHttpUrl(value: string) {
	try {
		return !isNullOrUndefined(new URL(value));
	} catch (error) {
		return false;
	};
}

