import { isString, isValidString } from "./core";

/**
 * ```str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)```
 * */
 export function camelToSnakeCase(str: string) {
	return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

/**
 * ```str.replace(/(\_\w)/g, (m) => m[1].toUpperCase())```
 * */
export function snakeToCamelCase(str: string) {
	return str.replace(/(\_\w)/g, (m) => m[1].toUpperCase());
}

/**
 * To camelCase
 * */
export function sentenceToCamelCase(str: string) {
	return str.replace(/\s+(.)/g, (match, group) => group.toUpperCase()).trim();
}

/**
 * Replace withe spaces with the second parameter, by default will just remove it.
 *
 * Input: `replaceSpaces('AAA ee BB H h j')`
 * Output: 'AAAeeBBHhj'
 *
 * Input: `replaceSpaces('AAA ee BB H h j', '_')`
 * Output: 'AAA_ee_BB_H_h_j'
 * */
export function replaceSpaces(str: string, newValue: string = '') {
	return replaceCharacters( str, ' ', newValue );
}

/**
 * Replace any character, by default will just find space characters and remove it.
 *
 * Input: `replaceSpaces('AAA ee BB H h j')`
 * Output: 'AAAeeBBHhj'
 *
 * Input: `replaceSpaces('AAA ee BB H h j', ' ', '+')`
 * Output: 'AAA+ee+BB+H+h+j'
 * */
export function replaceCharacters(str: string, character: string = ' ', newValue: string = '') {
	return str.replace(new RegExp(character, 'g'), newValue ).trim();
}

/**
 * Input: 'AAA ee BB H h j'
 * Output: 'AAAEe_BB_HHJ'
 * */
export function sentenceToConstName(str: string) {
	return str.replace(/\s+[A-Z]/g, letter => `_${letter.toUpperCase()}` ).replace(/\s+(.)/g, (match, group) => group.toUpperCase()).trim()
}

/**
 * Input: 'AAA ee BB H h j'
 * Output: 'AAAEe_BB_HHJ'
 * */
export function sentenceToSnakeCase(str: string) {
	return camelToSnakeCase( sentenceToCamelCase(str).split('').map((string, i) => i === 0 ? string.toLowerCase() : string).join('') );
}

export function getFileNameExtension(str: string) {
	return (isString(str) && !str.includes('.')) ? null : str.split('').reverse().join('').split('.')[0].split('').reverse().join('');
}

export function toSentenceCase(str: string) {
	return str.replace(/(\_\w)/g, (m) => ' ' + m[1]).replace(/[A-Z]/g, l => ` ${l}`).replace(/\s+/g, ' ').toLocaleLowerCase().trim().replace(/^\w/, l => l.toUpperCase());
}

/**
 * Check if the string is a email
 * */
 export function emailIsValid(email: string) {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

/**
 * Check if the string is alphabetic.
 *
 * You can allow any other character as second parameter, by default blank space is allowed.
 *
 * By default don't allow empty
 * */
export function isAlpha(str: string, allow = ' ', allowEmpty = false) {
	return isValidString(str) && new RegExp(`^${allowEmpty ? '$|':''}[a-z${allow}]+$`, 'i').test(str);
}

/**
 * Check if the string is alphabetic without blank spaces.
 *
 * By default don't allow empty
 * */
export function isAlphaWithoutSpaces(str: string, allowEmpty = false) {
	return isAlpha(str, '', allowEmpty);
}

/**
 * Check if the string is alphanumeric.
 *
 * You can allow any other character as second parameter.
 *
 * By default don't allow empty
 * */
export function isAlphanumeric(str: string, allow: string = '', allowEmpty = false) {
	return isValidString(str) && new RegExp(`^${allowEmpty ? '$|':''}[a-z0-9${allow}]+$`, 'i').test(str);
}

/**
 * Check if the string is numeric.
 *
 * You can allow any other character as second parameter.
 *
 * By default don't allow empty
 * */
export function isNumeric(str: string, allow: string = '', allowEmpty = false) {
	return isValidString(str) && new RegExp(`^${allowEmpty ? '$|':''}[0-9${allow}]+$`, 'i').test(str);
}

/**
 * Check if the string is alphanumeric and can have spaces and dash.
 *
 * By default don't allow empty
 * */
export function isAlphanumericSpaceAndDash(str: string, allowEmpty = false) {
	return isAlphanumeric(str, ' /', allowEmpty);
}

/**
 * Check if the string is alphanumeric and can have dot, spaces and dash.
 * */
export function isAlphanumericDotSpaceAndDash(str: string) {
	return isAlphanumeric(str, '. /');
}

/**
 * Check if the string is alphanumeric and can have dot, spaces and dash or can be a empty string.
 * */
export function IsEmptyOrAlphanumericDotSpaceAndDash(str: string) {
	return isAlphanumeric(str, '. /', true);
}

/**
 * Check if the string has a number.
 * */
export function hasANumber(str: string) {
	return isValidString(str) && new RegExp(`\\d`).test(str);
}

/**
 * Check if the string has a lowercase letter.
 * */
export function hasALowercaseLetter(str: string) {
	return isValidString(str) && new RegExp(`(?=.*[a-z])`).test(str);
}

/**
 * Check if the string has a uppercase letter.
 * */
export function hasAUppercaseLetter(str: string) {
	return isValidString(str) && new RegExp(`(?=.*[A-Z])`).test(str);
}

/**
 * Check if the string has a special character like: ```-+_!@#$%^&*., ?```
 * */
export function hasASpecialCharacter(str: string) {
	return isValidString(str) && new RegExp(`(?=.*[-+_!@#$%^&*., ?])`).test(str);
}
