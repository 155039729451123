export function urlFromBlob(blob: Blob) {
	return window.URL.createObjectURL(blob);
}

export function blobFromDataArray(dataArray: any[]) {
	return new Blob([new Uint8Array(dataArray).buffer]);
}

export function blobFromBuffer(buffer: Buffer) {
	return new Blob([buffer]);
}

export function blobFromString(stringBase64: string) {
	return fetch(stringBase64).then(image => image.blob());
}

export function blobFromUrl(url: string, headers: HeadersInit = {}) {
	return fetch(url, { headers }).then(image => image.blob());
}

export function convertBlobToBase64(blob: Blob): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onerror = reject;
		reader.onload = () => {
			resolve(reader.result as string);
		};
		reader.readAsDataURL(blob);
	});
}

export function downloadBlobFile(blob: Blob, fileName: string) {
	const url = urlFromBlob(blob);

	// IE 11
	if (window.navigator.msSaveBlob !== undefined) {
		window.navigator.msSaveBlob(blob, fileName);
		return;
	}

	downloadFileFromUrl(url, fileName);
}

function downloadFileFromUrl(url: string, fileName: string) {
	const a = document.createElement('a');
	a.style.display = 'none';
	a.href = url;
	a.download = fileName;

	document.body.appendChild(a);
	requestAnimationFrame(() => {
		a.click();
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);
	});
}

export async function downloadByFileUrl(url: string, fileName = 'file') {
	const blob = await blobFromUrl( url );
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.href = urlFromBlob( blob );
	a.download = fileName;
	a.click();
	document.body.removeChild(a);
}

export async function downloadByFileUrlAsHref(url: string, fileName = 'file') {
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.href = url;
	a.download = fileName;
	a.click();
	document.body.removeChild(a);
}

export function textToBlob( text: string ) {
	return new Blob([ text ], { type: 'text/plain' });
}
